import React, { Component } from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import Layout from "../../components/layout";
import WithProviders from "../../components/WithProviders";
import { Message } from "./../../lib/getMessage";

import "./technology.css";

import autoUpdates from "../images/update.svg";
import proven from "../images/proven.svg";
import lowCost from "../images/low-cost.svg";
import zeroTimetoMarket from "../images/zero-time-market.svg";
import flexible from "../images/flexible.svg";
import manageable from "../images/manageable.svg";
import manageOnTheGo from "../images/mobile-ready.svg";
import modular from "../images/modular.svg";
import secure from "../images/data-secure.svg";

import redis from "../images/redis.jpg";
import nodejs from "../images/node-js.jpg";
import android from "../images/android.jpg";
import git from "../images/git.jpg";
import ubuntu from "../images/ubuntu.jpg";
import swift3x from "../images/swift3x.png";
import dgraph3x from "../images/dgraph3x.png";
import kubernetes3x from "../images/kubernetes3x.png";
import php from "../images/php.jpg";
import solr from "../images/solr.jpg";
import neo4j from "../images/neo-4-j.jpg";
import ios from "../images/ios.jpg";
import apache from "../images/apache.jpg";
import mysql from "../images/mysql.jpg";
import kafka from "../images/kafka.jpg";
import java from "../images/java.jpg";
import golang from "../images/GoLang.png";
import react from "../images/React.png";

const techMainstays = [
  {
    heading: <Message dictKey="proven" />,
    description: <Message dictKey="proven.description" />,
    image: proven,
  },
  {
    heading: <Message dictKey="low.cost" />,
    description: <Message dictKey="low.cost.description" />,
    image: lowCost,
  },
  {
    heading: <Message dictKey="zero.time.to.market" />,
    description: <Message dictKey="zero.time.to.market.description" />,
    image: zeroTimetoMarket,
  },
  {
    heading: <Message dictKey="flexible.&.scalable" />,
    description: <Message dictKey="flexible.&.scalable.description" />,
    image: flexible,
  },
  {
    heading: <Message dictKey="manageable" />,
    description: <Message dictKey="manageable.description" />,
    image: manageable,
  },
  {
    heading: <Message dictKey="secure" />,
    description: <Message dictKey="secure.description" />,
    image: secure,
  },
  {
    heading: <Message dictKey="manage.on.the.go" />,
    description: <Message dictKey="manage.on.the.go.description" />,
    image: manageOnTheGo,
  },
  {
    heading: <Message dictKey="automatic.updates" />,
    description: <Message dictKey="automatic.updates.description" />,
    image: autoUpdates,
  },
  {
    heading: <Message dictKey="modular" />,
    description: <Message dictKey="modular.description" />,
    image: modular,
  },
];

const techImages = [
  redis,
  nodejs,
  android,
  git,
  ubuntu,
  golang,
  solr,
  swift3x,
  ios,
  dgraph3x,
  kubernetes3x,
  mysql,
  kafka,
  java,
  react,
];

const TechFetures = (props) => (
  <div className="feature-details">
    <div className="name">
      <div
        className={`feature-image ${
          props.isDirectionRTL && "feature-image-rtl"
        }`}
        style={{ backgroundImage: `url(${props.image})` }}
      />
      <div className="heading">{props.heading}</div>
    </div>
    <div className="explanation">{props.description}</div>
  </div>
);

class Technology extends Component {
  render() {
    const { language, updateLanguage, direction } = this.props;
    const isDirectionRTL = direction === "rtl";
    return (
      <Layout
        direction={direction}
        language={language}
        location={this.props.location}
      >
        <div>
          <Helmet title="Technology ZopSmart" />
          <Header
            current="technology"
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <div className="technology">
            <div className="brief">
              <div className="wrap">
                <h1>
                  <Message dictKey="zopSmart.technology" />
                </h1>
                <p>
                  <Message dictKey="technology.Description" />
                </p>
              </div>
            </div>
            <section className="technology-overview">
              <div className="technology-overview-wrapper">
                <div className="offer">
                  <h2>
                    <Message dictKey="what.we.offer" />
                  </h2>
                  <p>
                    <Message dictKey="what.we.offer.description" />
                  </p>
                </div>
                <div className="technology-overview-media">
                  <img
                    src="https://storage.googleapis.com/zopping-staging-uploads/1557/images/1024/20240429/89c4feb2-a04c-4d1e-ad16-f3e266416b2e-Group273x.webp"
                    alt="softwares"
                    loading="lazy"
                  />
                </div>
              </div>
            </section>
            <section className="technology-mainstays">
              <div className="header tc">
                <h2>
                  <Message dictKey="mainstays.technology" />
                </h2>
                <hr />
              </div>
              <div className="tech-features">
                {techMainstays.map((elm, idx) => {
                  return (
                    <TechFetures
                      isDirectionRTL={isDirectionRTL}
                      key={elm.heading + idx}
                      heading={elm.heading}
                      description={elm.description}
                      image={elm.image}
                    />
                  );
                })}
              </div>
            </section>
            <section className="technology-building-blocks">
              <div className="header tc">
                <h2>
                  <Message dictKey="building.blocks.our.technology" />
                </h2>
                <hr />
              </div>
              <div className="building-blocks-wrapper">
                {techImages.map((elm, idx) => {
                  return (
                    <div key={"img" + idx} className="building-blocks-images">
                      <img src={elm} alt="building-blocks" loading="lazy" />
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default WithProviders(Technology);
